import Rails from "@rails/ujs"
import "animate.css"
require("turbolinks").start()

try {
  Rails.start()
} catch (error) {
  console.error(error)
}

function importAll(r) {
  r.keys().forEach(r)
}
import "../sass/components/password-meter.scss"
importAll(require.context("../sass/abstract", true, /\.scss$/))
importAll(require.context("../sass/base", true, /\.scss$/))
importAll(require.context("../sass/layouts", true, /\.scss$/))
importAll(require.context("../sass/components", true, /\.scss$/))
importAll(require.context("../sass/forms", true, /\.scss$/))
importAll(require.context("../sass/devise", true, /\.scss$/))

import "controllers/base"
